@import url("https://cdn.syncfusion.com/ej2/20.3.56/bootstrap.css");

* {
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    /* font-family: 'Courier New', Courier, monospace; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* font-weight: bolder; */
}

.body {
    background-color: #2578cd;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    transition: filter 0.3s ease-in-out;
}



.bg-main {
    background-color: #202d3e;
}

.bg-orange {
    background-color: #ffa500;
}

.bg-footer {
    background-color: #F7EDE2;
}

.mainhome {
    height: 100vh;
}

.logB {
    color: orange;
    /* font-size: 45px; */
    /* transform: rotate(-40deg); */
    transition: all 0.3s ease-in-out;
}

.logB:hover {
    color: #ffa500;
    font-size: 45px;
    transform: rotate(-40deg);
}

.logA {
    color: #202d3e;
    /* font-size: 45px; */
    /* transform: rotate(-40deg); */
    transition: all 0.3s ease-in-out;
}

.logA:hover {
    color: #202d3e;
    font-size: 45px;
    transform: rotate(-40deg);
}

.editorMain {
    height: 100vh;
    overflow: scroll;

}



.chapter_title {
    text-decoration: underline;
}

.section_body {
    text-align: justify;
}

.editorholder {
    position: relative;
}

.editbtn {
    position: absolute;
    top: 0;
    right: -35px;
    z-index: 1;
    background-color: #202d3e;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.buttonComp {
    background-color: #FFA500;
    /* color: white; */
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}


.buttonCompGenerate {
    background-color: #FFA500;
    /* color: white; */
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.navlink {
    color: white;
    text-decoration: none;
}

.navlink:hover {
    color: #ffa500;
    text-decoration: none;
}

.navlist {
    list-style-type: none;
}

.navlist li {
    /* display: inline-block; */
    position: relative;
    padding: 10px;
}

/* 
.navlist li:hover {
    color: #ffa500;
}

a {
    position: relative;
    text-decoration: none;
    color: #000; 
  } */

/* Pseudo-element for the growing border */
.navlist li::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    /* Change this to your desired border height */
    background-color: #ffa500;
    /* Change this to your desired border color */
    transition: width 0.3s ease;
    /* Adjust the transition duration as needed */
}

/* Hover effect */
.navlist li:hover::before {
    width: 100%;
}

.navlist li span {
    display: inline;

}


.subscribe {
    background-color: rgba(0, 0, 0, 0.058);
    border-radius: 10px;
    color: #202d3e;
}

.menusubitem a {
    text-decoration: none;
    margin-bottom: 10px;
}

.menusubitem {
    background-color: rgba(255, 255, 255, 0.094);
    width: 250px;
    border-radius: 10px;
    padding: 10px;
}



/* .dimmerClass {
    filter: brightness(50%);
} */
/* 

.dimmerClass:not(#element2) {
    filter: brightness(50%);
} */

#element2 {
    border: 2px solid green;
    /* filter: brightness(100%); */
    transform: scale(1.1);

    z-index: 999999;
}